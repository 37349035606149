/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = (property) => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "project") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  } else if (property === "scale") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.node.data.scale - a.node.data.scale;
      } else {
        return a.node.data.scale - b.node.data.scale;
      }
    };
  } else if (property === "year") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.node.data.year - a.node.data.year;
      } else {
        return a.node.data.year - b.node.data.year;
      }
    };
  } else if (property === "category") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.node.data.categories[0].category.localeCompare(
          a.node.data.categories[0].category
        );
      } else {
        return a.node.data.categories[0].category.localeCompare(
          b.node.data.categories[0].category
        );
      }
    };
  } else {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.node.data[property].localeCompare(a.node.data[property]);
      } else {
        return a.node.data[property].localeCompare(b.node.data[property]);
      }
    };
  }
};
