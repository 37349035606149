import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Media from "react-media";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Icons
import { ActiveSortByIcon } from "../components/icons/active-sort-by-icon";

// Utils
import { dynamicSort } from "../components/utils/dynamic-sort";

// SEO
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  position: relative;
  padding: 50px 0 0 0;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  align-items: baseline;

  @media (max-width: 860px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    padding: 70px 0 0 0;
  }
`;

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;

  // grid-column: 4 / 13;
  grid-column: 3 / 13;

  @media (max-width: 1080px) {
    grid-column: 5 / 13;
  }

  @media (max-width: 860px) {
    grid-column: unset;
  }

  & .sort-headings-container {
    position: relative;
    grid-column: 1 / 13;

    & .inner-sort-headings-container {
      display: grid;
      // grid-template-columns: 2fr 2fr 1fr 1fr 90px;
      grid-template-columns: 270px 2fr 1fr 150px 90px;
      grid-column-gap: 30px;

      grid-column: 1 / 13;

      transition: 350ms color ease;

      // padding: 0 0 9px 0;

      @media (max-width: 1680px) {
        grid-template-columns: 270px 2fr 150px 90px;
      }

      @media (max-width: 1330px) {
        grid-template-columns: 270px 2fr 90px;
      }

      @media (max-width: 1080px) {
        grid-template-columns: 1fr 90px;
        grid-column-gap: 20px;
      }

      @media (max-width: 860px) {
        grid-template-columns: 1fr 75px;
      }
    }

    & .sort-heading {
      & button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-items: baseline;

        width: 100%;

        font-size: 20px;
        // line-height: 130%;
        // letter-spacing: 0.08em;
        // text-transform: uppercase;

        & span {
          margin: 0 0 0 20px;
        }

        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }

      @media (max-width: 1680px) {
        &.sort-by-client {
          display: none;
        }
      }

      @media (max-width: 1330px) {
        &.sort-by-scale {
          display: none;
        }
      }

      @media (max-width: 1080px) {
        &.sort-by-client,
        &.sort-by-scale,
        &.sort-by-location {
          display: none;
        }
      }
    }
  }

  & ol {
    grid-column: 1 / 13;

    & li {
      position: relative;

      &:hover {
        & .image-container {
          display: block;
        }
      }

      &:first-of-type {
        padding: 3px 0 0 0;
        // padding: 9px 0 0 0;
      }

      & .project-content-container {
        // border-bottom: 2px solid #000;
        transition: 350ms color ease;

        & .category {
          & .single-category {
            text-transform: capitalize;

            &::after {
              content: ", ";
            }

            &:last-of-type {
              &::after {
                content: "";
              }
            }
          }
        }

        & a {
          display: block;

          font-size: 20px;
          line-height: 36px;

          @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 34px;
          }
        }

        & .grid {
          display: grid;
          // grid-template-columns: 2fr 2fr 1fr 1fr 90px;
          grid-template-columns: 270px 2fr 1fr 150px 90px;

          grid-column-gap: 30px;

          @media (max-width: 1680px) {
            grid-template-columns: 270px 2fr 150px 90px;
          }

          @media (max-width: 1330px) {
            grid-template-columns: 270px 2fr 90px;
          }

          @media (max-width: 1080px) {
            grid-template-columns: 1fr 90px;
            grid-column-gap: 20px;
          }

          @media (max-width: 860px) {
            grid-template-columns: 1fr 75px;
          }
        }
      }

      &:last-of-type {
        & .project-content-container {
          border-bottom: 0;
        }
      }

      @media (max-width: 1680px) {
        & .client {
          display: none;
        }
      }

      @media (max-width: 1330px) {
        & .scale {
          display: none;
        }
      }

      @media (max-width: 1080px) {
        & .scale,
        & .client,
        & .location {
          display: none;
        }
      }
    }
  }
`;

const SidebarContainer = styled.div`
  position: sticky;
  top: 90px;
  left: 0;
  // grid-column: 1 / 4;
  grid-column: 1 / 3;

  @media (max-width: 1080px) {
    grid-column: 1 / 5;
  }

  @media (max-width: 860px) {
    display: none;
  }
`;

const ActiveProjectContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  // transform: translateY(${(props) => props.top}px);
  // transition: 300ms transform ease-out;

  // position: sticky;
  // top: 180px;

  // grid-column: 1 / 4;
  width: 100%;
  z-index: 20;

  // @media (max-width: 1024px) {
  //   grid-column: 1 / 5;
  // }

  // @media (max-width: 860px) {
  //   display: none;
  // }
`;

const FiltersContainer = styled.div`
  // grid-column: 1 / 3;

  z-index: 10;
  // border-right: 2px solid #000;

  margin: 0 0 25px 0;

  transition: 250ms opacity ease;

  opacity: 1;
  visibility: visible;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  & .filters-toggle-container {
    margin: 1em 0 0 0;
  }

  & button {
    display: block;

    font-size: 20px;
    line-height: 130%;

    text-transform: capitalize;

    padding: 0;
    margin: 0;
    appearance: none;
    border: 0;

    transition: 350ms color ease;
    cursor: pointer;

    & svg {
      margin: 0 0 0 10px;
    }

    @media (max-width: 860px) {
      font-size: 18px;
      line-height: 34px;
    }
  }

  & .mobile-filter-toggle {
    display: none;
  }

  @media (max-width: 1024px) {
    // grid-column: 1 / 5;

    & button {
      font-size: 18px;
    }
  }

  @media (max-width: 860px) {
    // grid-column: unset;

    & .mobile-filter-toggle {
      display: block;
    }

    & ol {
      &.mobile-filters-closed {
        display: none;
      }

      &.mobile-filters-open {
        display: block;
      }
    }
  }
`;

const MobileList = ({ content }) => {
  return (
    <ol>
      {content.map((project, index) => {
        return (
          <li key={`single_project_mobile_${project.node.id}_${index}`}>
            <div className="project-content-container">
              <Link to={project.node.url}>
                <div className="grid">
                  <div className="title">{project.node.data.title.text}</div>
                  <div className="year tnum">
                    {project.node.data.fallback_year !== null &&
                    project.node.data.fallback_year !== ""
                      ? project.node.data.fallback_year
                      : project.node.data.year}
                  </div>
                </div>
              </Link>
            </div>
          </li>
        );
      })}
    </ol>
  );
};

const DesktopList = ({ content, setActiveProject, setActiveProjectIndex }) => {
  return (
    <ol>
      {content.map((project, index) => {
        return (
          <li
            key={`single_project_desktop_${project.node.id}_${index}`}
            onMouseEnter={() => {
              setActiveProject(project.node.data);
              setActiveProjectIndex(index);
            }}
            onMouseLeave={() => {
              setActiveProject(null);
              setActiveProjectIndex(0);
            }}
          >
            <div className="project-content-container">
              <Link to={project.node.url}>
                <div className="grid">
                  <div className="title">{project.node.data.title.text}</div>

                  <div className="location">{project.node.data.location}</div>

                  <div className="client">
                    {project.node.data.client !== null &&
                      project.node.data.client}
                  </div>

                  <div className="scale tnum">
                    {project.node.data.scale !== null && (
                      <>
                        {project.node.data.scale.toLocaleString("en-GB")}m
                        <sup>²</sup>
                      </>
                    )}
                  </div>
                  {/* <div className="category">
                  {project.node.data.categories
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map((category, index) => (
                      <span
                        key={`single_category_${index}`}
                        className="single-category"
                      >
                        {category.category.toLowerCase()}
                      </span>
                    ))}
                </div> */}
                  <div className="year tnum">
                    {project.node.data.fallback_year !== null &&
                    project.node.data.fallback_year !== ""
                      ? project.node.data.fallback_year
                      : project.node.data.year}
                  </div>
                </div>
              </Link>
            </div>
          </li>
        );
      })}
    </ol>
  );
};

const Projects = ({ data }) => {
  const [activeFilters, setActiveFilters] = useState(null);
  const [activeSort, setActiveSort] = useState(`-year`);
  const [activeProject, setActiveProject] = useState(null);
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(860);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`projects`);
  }, []);

  const content = data.allPrismicProject.edges
    .filter((project) => {
      if (activeFilters !== null) {
        if (
          project.node.data.categories
            .filter((item) => item.category !== null)
            .some((item) => item.category.toLowerCase() === activeFilters)
        ) {
          return project;
        }
      } else {
        return project;
      }
    })
    .sort(dynamicSort(activeSort));

  const filters = [
    ...new Set(
      data.allPrismicProject.edges
        .map((filter) =>
          filter.node.data.categories
            .filter((category) => category.category !== null)
            .map((category) => category.category.toLowerCase())
        )
        .flat()
    ),
  ].sort();

  const categoryFilters = filters.map((category, index) => (
    <li
      key={`single_project_filter_${index}`}
      className={
        activeFilters === category
          ? `active-filter highlight-color`
          : ` highlight-color`
      }
    >
      <button
        onClick={() => {
          if (activeFilters === category) {
            setActiveFilters(null);
          } else {
            setActiveFilters(category);
          }
        }}
      >
        {category}
      </button>
    </li>
  ));

  return (
    <>
      <PageSEO
        title={
          data.prismicProjects.data.seo_title !== null
            ? data.prismicProjects.data.seo_title
            : data.prismicProjects.data.title.text
        }
        image={
          data.prismicProjects.data.seo_image !== null
            ? data.prismicProjects.data.seo_image.url
            : null
        }
        description={data.prismicProjects.data.seo_description}
        url={`https://mcmullanstudio.com${data.prismicProjects.url}`}
      />

      <Page>
        <SidebarContainer>
          <ActiveProjectContainer>
            {/* top={activeProjectIndex * 38 + 38 + 9 + 50}> */}
            {activeProject !== null && (
              <>
                {activeProject.thumbnail.fluid !== null && (
                  <img
                    srcSet={activeProject.thumbnail.fluid.srcSetWebp}
                    src={activeProject.thumbnail.fluid.srcWebp}
                    alt={activeProject.thumbnail.alt}
                    loading={`lazy`}
                  />
                )}
              </>
            )}
          </ActiveProjectContainer>

          <FiltersContainer
            className={`categories-container ${
              activeProject === null ? `visible` : `hidden`
            }`}
          >
            <button
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              className="mobile-filter-toggle"
            >
              {isFiltersOpen ? `Close` : `Filter`}
            </button>

            <ol
              className={
                isFiltersOpen ? `mobile-filters-open` : `mobile-filters-closed`
              }
            >
              {categoryFilters}
            </ol>

            <div className="filters-toggle-container">
              {activeFilters !== null && (
                <button
                  onClick={() => setActiveFilters(null)}
                  className="highlight-color"
                >
                  Show All
                </button>
              )}
            </div>
          </FiltersContainer>
        </SidebarContainer>

        <ProjectsContainer>
          <div className="sort-headings-container">
            <div className="inner-sort-headings-container">
              <div className="sort-heading sort-by-project">
                <button
                  className="bold-text"
                  onClick={() =>
                    setActiveSort(
                      activeSort === "project" ? "-project" : "project"
                    )
                  }
                >
                  Project
                  {activeSort === "project" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={0} />
                    </span>
                  )}
                  {activeSort === "-project" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={180} />
                    </span>
                  )}
                </button>
              </div>

              <div className="sort-heading sort-by-location">
                <button
                  className="bold-text"
                  onClick={() =>
                    setActiveSort(
                      activeSort === "location" ? "-location" : "location"
                    )
                  }
                >
                  Location
                  {activeSort === "location" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={0} />
                    </span>
                  )}
                  {activeSort === "-location" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={180} />
                    </span>
                  )}
                </button>
              </div>

              <div className="sort-heading sort-by-client">
                <button
                  className="bold-text"
                  onClick={() =>
                    setActiveSort(
                      activeSort === "client" ? "-client" : "client"
                    )
                  }
                >
                  Client{" "}
                  {activeSort === "client" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={0} />
                    </span>
                  )}
                  {activeSort === "-client" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={180} />
                    </span>
                  )}
                </button>
              </div>

              <div className="sort-heading sort-by-scale">
                <button
                  className="bold-text"
                  onClick={() =>
                    setActiveSort(activeSort === "scale" ? "-scale" : "scale")
                  }
                >
                  Scale{" "}
                  {activeSort === "scale" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={0} />
                    </span>
                  )}
                  {activeSort === "-scale" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={180} />
                    </span>
                  )}
                </button>
              </div>
              {/* <div className="sort-heading sort-by-category">
              <button
                className="bold-text"
                onClick={() =>
                  setActiveSort(
                    activeSort === "category" ? "-category" : "category"
                  )
                }
              >
                Category
                {activeSort === "category" && (
                  <span className="arrow">
                    <ActiveSortByIcon rotate={0} />
                  </span>
                )}
                {activeSort === "-category" && (
                  <span className="arrow">
                    <ActiveSortByIcon rotate={180} />
                  </span>
                )}
              </button>
            </div> */}

              <div className="sort-heading sort-by-year">
                <button
                  className="bold-text"
                  onClick={() =>
                    setActiveSort(activeSort === "year" ? "-year" : "year")
                  }
                >
                  Year{" "}
                  {activeSort === "year" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={180} />
                    </span>
                  )}
                  {activeSort === "-year" && (
                    <span className="arrow">
                      <ActiveSortByIcon rotate={0} />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Media
            queries={{ medium: "(max-width: 860px)" }}
            defaultMatches={{ medium: windowWidth === 860 }}
            render={() => <MobileList content={content} />}
          />

          <Media
            queries={{ medium: "(min-width: 861px)" }}
            defaultMatches={{ medium: windowWidth === 861 }}
            render={() => (
              <DesktopList
                content={content}
                setActiveProject={setActiveProject}
                setActiveProjectIndex={setActiveProjectIndex}
              />
            )}
          />
        </ProjectsContainer>
      </Page>
    </>
  );
};

export default withPreview(Projects);

export const query = graphql`
  {
    prismicProjects {
      url
      data {
        seo_title
        seo_image {
          url
        }
        seo_description
        title {
          text
        }
      }
    }
    allPrismicProject(sort: { fields: data___year, order: DESC }) {
      edges {
        node {
          url
          id
          data {
            title {
              text
            }
            client
            scale
            categories {
              category
            }
            location
            year
            fallback_year
            thumbnail {
              fluid {
                srcWebp
                srcSetWebp
              }
              alt
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;
