import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  transform: rotateX(${props => props.rotate}deg);
`;

export const ActiveSortByIcon = ({ rotate }) => (
  <Icon
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    rotate={rotate}
  >
    <path
      d="M6.42863 16.56C6.73788 16.8755 7.24438 16.8806 7.55994 16.5714C7.8755 16.2621 7.88062 15.7556 7.57137 15.4401L6.42863 16.56ZM1.57137 9.31762C1.26212 9.00206 0.755616 8.99695 0.440057 9.30619C0.124498 9.61544 0.119381 10.1219 0.428629 10.4375L1.57137 9.31762ZM7.57137 15.4401L1.57137 9.31762L0.428629 10.4375L6.42863 16.56L7.57137 15.4401Z"
      fill="black"
    />
    <path
      d="M13.5714 10.4375C13.8806 10.1219 13.8755 9.61544 13.5599 9.30619C13.2444 8.99695 12.7379 9.00206 12.4286 9.31762L13.5714 10.4375ZM6.42863 15.4401C6.11938 15.7556 6.1245 16.2621 6.44006 16.5714C6.75562 16.8806 7.26212 16.8755 7.57137 16.56L6.42863 15.4401ZM12.4286 9.31762L6.42863 15.4401L7.57137 16.56L13.5714 10.4375L12.4286 9.31762Z"
      fill="black"
    />
    <path
      d="M6.2 16C6.2 16.4418 6.55817 16.8 7 16.8C7.44183 16.8 7.8 16.4418 7.8 16H6.2ZM7.8 1C7.8 0.558172 7.44183 0.2 7 0.2C6.55817 0.2 6.2 0.558172 6.2 1H7.8ZM7.8 16V1H6.2V16H7.8Z"
      fill="black"
    />
  </Icon>
);
